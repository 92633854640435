import React, { Component } from 'react';

export default class AsideMenuItem extends Component {
  isChild(parent, slug) {
    if (parent.children !== undefined) {
      return ( parent.children.findIndex((e) => e.id === slug) >= 0 )
    }
  }

  getParentOfActive(headers, slug) {
    const BreakException = {}
    let parent
    try {
      headers.forEach((header, i) => {
          parent = header
          if (header.children !== undefined) {
            const isActive = (e) => e.id === slug
            if (header.children.some(isActive)) throw BreakException;
          }
      });
    } catch (e) {
      if (e === BreakException) return parent;
    }
  }

  isSubChild(children, slug) {
    if (children !== undefined) {
      const isActive = (child) => this.isChild(child, slug);
      return (children.some(isActive))
    }
  }

  render() {
    const { link, active } = this.props
    const sublinks = link.children
    const isSublinkActive = this.isChild(link, active)
    const isH3linkActive = this.isSubChild(sublinks, active)
    const h3ActiveParent = this.getParentOfActive(sublinks, active)
    const h3ActiveParentId = h3ActiveParent == undefined ? "undefined" : h3ActiveParent.id

    return (
        <li className={`aside-menu__list-item ${active === link.id ? "aside-menu__list-item-active" : (isSublinkActive ? "aside-menu__list-item-active-parent" : "")}`}>
          <a
            href={`#${link.id}`}
            className="link h1"
          >{link.textNode}</a>

          <ul
            className={`sublist-menu ${isSublinkActive ? " sublist-menu-active" : ""}`}
            style={{ display: active === link.id || isSublinkActive || isH3linkActive ? "block" : "none" }}
          >
            {sublinks == undefined ? "" : sublinks.map((sublink) => (

              <li key={sublink.id} className={`sublist-menu__list-item ${active === sublink.id ? "sublist-menu__list-item-active" : (isH3linkActive ? "sublist-menu__list-item-active-parent" : "")}`}>
                <a href={`#${sublink.id}`}
                  className="link h2"
                >{sublink.textNode}</a>

                <ul
                  className={`sub-sublist-menu ${h3ActiveParentId === sublink.id ? " sub-sublist-menu-active" : ""}`}
                  style={{ display: active === sublink.id || h3ActiveParentId === sublink.id ? "block" : "none" }}
                >

                    {sublink.children == undefined ? "" : sublink.children.map((h3link) => (

                        <li key={h3link.id} className={`sub-sublist-menu__list-item ${active === h3link.id ? "sub-sublist-menu__list-item-active" : ""}`}>
                          <a
                            href={`#${h3link.id}`}
                            className="link"
                          >{h3link.textNode}</a>
                        </li>

                    ))}
                </ul>
              </li>

            ))}
          </ul>
        </li>
    )

  }
}
